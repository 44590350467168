export const weekReleaseRewards = [
  {
    totalYear: '1',
    supply: '5.58%',
    annualWeeklyBatch: '13,962,273',
    rewards: [
      304259,
      302737,
      301224,
      299718,
      298219,
      296728,
      295244,
      293768,
      292299,
      290838,
      289384,
      287937,
      286497,
      285064,
      283639,
      282221,
      280810,
      279406,
      278009,
      276619,
      275236,
      273859,
      272490,
      271128,
      269772,
      268423,
      267081,
      265746,
      264417,
      263095,
      261779,
      260470,
      259168,
      257872,
      256583,
      255300,
      254024,
      252753,
      251490,
      250232,
      248981,
      247736,
      246497,
      245265,
      244039,
      242818,
      241604,
      240396,
      239194,
      237998,
      236808,
      235399,
    ],
  },
  {
    totalYear: '2',
    supply: '4.96%',
    annualWeeklyBatch: '12,402,899',
    rewards: [
      270273,
      268922,
      267577,
      266239,
      264908,
      263584,
      262266,
      260954,
      259650,
      258351,
      257060,
      255774,
      254496,
      253223,
      251957,
      250697,
      249444,
      248196,
      246955,
      245721,
      244492,
      243270,
      242053,
      240843,
      239639,
      238441,
      237248,
      236062,
      234882,
      233707,
      232539,
      231376,
      230219,
      229068,
      227923,
      226783,
      225649,
      224521,
      223398,
      222282,
      221170,
      220064,
      218964,
      217869,
      216780,
      215696,
      214617,
      213544,
      212477,
      211414,
      210357,
      209305,
    ],
  },
  {
    totalYear: '3',
    supply: '4.41%',
    annualWeeklyBatch: '11,017,514',
    rewards: [
      240084,
      238884,
      237689,
      236501,
      235318,
      234142,
      232971,
      231806,
      230647,
      229494,
      228346,
      227205,
      226069,
      224938,
      223814,
      222695,
      221581,
      220473,
      219371,
      218274,
      217183,
      216097,
      215016,
      213941,
      212871,
      211807,
      210748,
      209694,
      208646,
      207603,
      206565,
      205532,
      204504,
      203482,
      202464,
      201452,
      200445,
      199442,
      198445,
      197453,
      196466,
      195483,
      194506,
      193533,
      192566,
      191603,
      190645,
      189692,
      188743,
      187799,
      186860,
      185926,
    ],
  },
  {
    totalYear: '4',
    supply: '3.91%',
    annualWeeklyBatch: '9,786,867',
    rewards: [
      213267,
      212201,
      211140,
      210084,
      209033,
      207988,
      206948,
      205914,
      204884,
      203860,
      202840,
      201826,
      200817,
      199813,
      198814,
      197820,
      196831,
      195847,
      194867,
      193893,
      192923,
      191959,
      190999,
      190044,
      189094,
      188148,
      187208,
      186272,
      185340,
      184414,
      183491,
      182574,
      181661,
      180753,
      179849,
      178950,
      178055,
      177165,
      176279,
      175398,
      174521,
      173648,
      172780,
      171916,
      171056,
      170201,
      169350,
      168503,
      167661,
      166822,
      165988,
      165158,
    ],
  },
  {
    totalYear: '5',
    supply: '3.48%',
    annualWeeklyBatch: '8,693,684',
    rewards: [
      189445,
      188498,
      187555,
      186618,
      185685,
      184756,
      183832,
      182913,
      181999,
      181089,
      180183,
      179282,
      178386,
      177494,
      176607,
      175723,
      174845,
      173971,
      173101,
      172235,
      171374,
      170517,
      169665,
      168816,
      167972,
      167132,
      166297,
      165465,
      164638,
      163815,
      162996,
      162181,
      161370,
      160563,
      159760,
      158961,
      158167,
      157376,
      156589,
      155806,
      155027,
      154252,
      153480,
      152713,
      151949,
      151190,
      150434,
      149682,
      148933,
      148189,
      147448,
      146710,
    ],
  },
  {
    totalYear: '6',
    supply: '3.09%',
    annualWeeklyBatch: '7,722,607',
    rewards: [
      168284,
      167443,
      166606,
      165773,
      164944,
      164119,
      163299,
      162482,
      161670,
      160861,
      160057,
      159257,
      158460,
      157668,
      156880,
      156095,
      155315,
      154538,
      153766,
      152997,
      152232,
      151471,
      150713,
      149960,
      149210,
      148464,
      147722,
      146983,
      146248,
      145517,
      144789,
      144065,
      143345,
      142628,
      141915,
      141205,
      140499,
      139797,
      139098,
      138402,
      137710,
      137022,
      136337,
      135655,
      134977,
      134302,
      133630,
      132962,
      132298,
      131636,
      130978,
      130323,
    ],
  },
  {
    totalYear: '7',
    supply: '2.74%',
    annualWeeklyBatch: '6,860,000',
    rewards: [
      149487,
      148740,
      147996,
      147256,
      146520,
      145787,
      145058,
      144333,
      143611,
      142893,
      142179,
      141468,
      140761,
      140057,
      139356,
      138660,
      137966,
      137277,
      136590,
      135907,
      135228,
      134552,
      133879,
      133209,
      132543,
      131881,
      131221,
      130565,
      129912,
      129263,
      128616,
      127973,
      127333,
      126697,
      126063,
      125433,
      124806,
      124182,
      123561,
      122943,
      122328,
      121717,
      121108,
      120503,
      119900,
      119301,
      118704,
      118111,
      117520,
      116932,
      116348,
      115766,
    ],
  },
  {
    totalYear: '8',
    supply: '2.44%',
    annualWeeklyBatch: '6,093,745',
    rewards: [
      132790,
      132126,
      131465,
      130808,
      130154,
      129503,
      128855,
      128211,
      127570,
      126932,
      126298,
      125666,
      125038,
      124413,
      123790,
      123172,
      122556,
      121943,
      121333,
      120727,
      120123,
      119522,
      118925,
      118330,
      117738,
      117150,
      116564,
      115981,
      115401,
      114824,
      114250,
      113679,
      113110,
      112545,
      111982,
      111422,
      110865,
      110311,
      109759,
      109210,
      108664,
      108121,
      107580,
      107043,
      106507,
      105975,
      105445,
      104918,
      104393,
      103871,
      103352,
      102835,
    ],
  },
  {
    totalYear: '9',
    supply: '2.17%',
    annualWeeklyBatch: '5,413,080',
    rewards: [
      117957,
      117367,
      116781,
      116197,
      115616,
      115038,
      114462,
      113890,
      113321,
      112754,
      112190,
      111629,
      111071,
      110516,
      109963,
      109413,
      108866,
      108322,
      107780,
      107241,
      106705,
      106172,
      105641,
      105113,
      104587,
      104064,
      103544,
      103026,
      102511,
      101998,
      101488,
      100981,
      100476,
      99974,
      99474,
      98976,
      98482,
      97989,
      97499,
      97012,
      96527,
      96044,
      95564,
      95086,
      94611,
      94138,
      93667,
      93199,
      92733,
      92269,
      91808,
      91348,
    ],
  },
  {
    totalYear: '10',
    supply: '1.92%',
    annualWeeklyBatch: '4,808,443',
    rewards: [
      104781,
      104258,
      103736,
      103218,
      102701,
      102188,
      101677,
      101169,
      100663,
      100159,
      99659,
      99160,
      98665,
      98171,
      97680,
      97192,
      96706,
      96223,
      95741,
      95263,
      94786,
      94312,
      93841,
      93372,
      92905,
      92440,
      91978,
      91518,
      91061,
      90605,
      90152,
      89702,
      89253,
      88807,
      88363,
      87921,
      87481,
      87044,
      86609,
      86176,
      85745,
      85316,
      84889,
      84465,
      84043,
      83622,
      83204,
      82788,
      82374,
      81963,
      81553,
      81145,
    ],
  },
  {
    totalYear: '11',
    supply: '1.71%',
    annualWeeklyBatch: '4,271,345',
    rewards: [
      93077,
      92612,
      92149,
      91688,
      91230,
      90774,
      90320,
      89868,
      89419,
      88972,
      88527,
      88084,
      87644,
      87206,
      86770,
      86336,
      85904,
      85475,
      85047,
      84622,
      84199,
      83778,
      83359,
      82942,
      82527,
      82115,
      81704,
      81296,
      80889,
      80485,
      80082,
      79682,
      79284,
      78887,
      78493,
      78100,
      77710,
      77321,
      76935,
      76550,
      76167,
      75786,
      75407,
      75030,
      74655,
      74282,
      73911,
      73541,
      73173,
      72807,
      72443,
      72081,
    ],
  },
  {
    totalYear: '12',
    supply: '1.52%',
    annualWeeklyBatch: '3,794,245',
    rewards: [
      82681,
      82267,
      81856,
      81447,
      81040,
      80634,
      80231,
      79830,
      79431,
      79034,
      78639,
      78245,
      77854,
      77465,
      77078,
      76692,
      76309,
      75927,
      75548,
      75170,
      74794,
      74420,
      74048,
      73678,
      73309,
      72943,
      72578,
      72215,
      71854,
      71495,
      71137,
      70782,
      70428,
      70076,
      69725,
      69377,
      69030,
      68684,
      68341,
      67999,
      67659,
      67321,
      66984,
      66650,
      66316,
      65985,
      65655,
      65327,
      65000,
      64675,
      64352,
      64030,
    ],
  },
  {
    totalYear: '13',
    supply: '1.35%',
    annualWeeklyBatch: '3,370,428',
    rewards: [
      73445,
      73078,
      72713,
      72349,
      71988,
      71628,
      71269,
      70913,
      70559,
      70206,
      69855,
      69505,
      69158,
      68812,
      68468,
      68126,
      67785,
      67446,
      67109,
      66773,
      66440,
      66107,
      65777,
      65448,
      65121,
      64795,
      64471,
      64149,
      63828,
      63509,
      63191,
      62875,
      62561,
      62248,
      61937,
      61627,
      61319,
      61013,
      60707,
      60404,
      60102,
      59801,
      59502,
      59205,
      58909,
      58614,
      58321,
      58030,
      57739,
      57451,
      57164,
      56878,
    ],
  },
  {
    totalYear: '14',
    supply: '1.20%',
    annualWeeklyBatch: '2,993,957',
    rewards: [
      65242,
      64915,
      64591,
      64268,
      63947,
      63627,
      63309,
      62992,
      62677,
      62364,
      62052,
      61742,
      61433,
      61126,
      60820,
      60516,
      60214,
      59912,
      59613,
      59315,
      59018,
      58723,
      58430,
      58137,
      57847,
      57558,
      57270,
      56983,
      56698,
      56415,
      56133,
      55852,
      55573,
      55295,
      55019,
      54744,
      54470,
      54197,
      53926,
      53657,
      53389,
      53122,
      52856,
      52592,
      52329,
      52067,
      51807,
      51548,
      51290,
      51034,
      50778,
      50525,
    ],
  },
  {
    totalYear: '15',
    supply: '1.06%',
    annualWeeklyBatch: '2,659,532',
    rewards: [
      57954,
      57664,
      57376,
      57089,
      56804,
      56520,
      56237,
      55956,
      55676,
      55398,
      55121,
      54845,
      54571,
      54298,
      54027,
      53757,
      53488,
      53220,
      52954,
      52689,
      52426,
      52164,
      51903,
      51644,
      51385,
      51128,
      50873,
      50618,
      50365,
      50113,
      49863,
      49614,
      49366,
      49119,
      48873,
      48629,
      48386,
      48144,
      47903,
      47663,
      47425,
      47188,
      46952,
      46717,
      46484,
      46251,
      46020,
      45790,
      45561,
      45333,
      45107,
      44881,
    ],
  },
  {
    totalYear: '16',
    supply: '0.94%',
    annualWeeklyBatch: '2,362,465',
    rewards: [
      51481,
      51223,
      50967,
      50712,
      50459,
      50207,
      49956,
      49706,
      49457,
      49210,
      48964,
      48719,
      48475,
      48233,
      47992,
      47752,
      47513,
      47276,
      47039,
      46804,
      46570,
      46337,
      46106,
      45875,
      45646,
      45417,
      45190,
      44964,
      44740,
      44516,
      44293,
      44072,
      43851,
      43632,
      43414,
      43197,
      42981,
      42766,
      42552,
      42339,
      42128,
      41917,
      41708,
      41499,
      41292,
      41085,
      40880,
      40675,
      40472,
      40270,
      40068,
      39868,
    ],
  },
  {
    totalYear: '17',
    supply: '0.84%',
    annualWeeklyBatch: '2,098,581',
    rewards: [
      45730,
      45502,
      45274,
      45048,
      44823,
      44599,
      44376,
      44154,
      43933,
      43713,
      43495,
      43277,
      43061,
      42846,
      42631,
      42418,
      42206,
      41995,
      41785,
      41576,
      41368,
      41161,
      40956,
      40751,
      40547,
      40344,
      40143,
      39942,
      39742,
      39543,
      39346,
      39149,
      38953,
      38759,
      38565,
      38372,
      38180,
      37989,
      37799,
      37610,
      37422,
      37235,
      37049,
      36864,
      36679,
      36496,
      36313,
      36132,
      35951,
      35771,
      35593,
      35415,
    ],
  },
  {
    totalYear: '18',
    supply: '0.75%',
    annualWeeklyBatch: '1,864,170',
    rewards: [
      40622,
      40419,
      40217,
      40016,
      39816,
      39617,
      39419,
      39222,
      39026,
      38831,
      38636,
      38443,
      38251,
      38060,
      37869,
      37680,
      37492,
      37304,
      37118,
      36932,
      36747,
      36564,
      36381,
      36199,
      36018,
      35838,
      35659,
      35480,
      35303,
      35127,
      34951,
      34776,
      34602,
      34429,
      34257,
      34086,
      33915,
      33746,
      33577,
      33409,
      33242,
      33076,
      32911,
      32746,
      32582,
      32419,
      32257,
      32096,
      31935,
      31776,
      31617,
      31459,
    ],
  },
  {
    totalYear: '19',
    supply: '0.66%',
    annualWeeklyBatch: '1,655,944',
    rewards: [
      36085,
      35904,
      35725,
      35546,
      35369,
      35192,
      35016,
      34841,
      34667,
      34493,
      34321,
      34149,
      33978,
      33808,
      33639,
      33471,
      33304,
      33137,
      32972,
      32807,
      32643,
      32480,
      32317,
      32156,
      31995,
      31835,
      31676,
      31517,
      31360,
      31203,
      31047,
      30892,
      30737,
      30584,
      30431,
      30278,
      30127,
      29976,
      29827,
      29677,
      29529,
      29381,
      29234,
      29088,
      28943,
      28798,
      28654,
      28511,
      28368,
      28226,
      28085,
      27945,
    ],
  },
  {
    totalYear: '20',
    supply: '0.59%',
    annualWeeklyBatch: '1,470,980',
    rewards: [
      32054,
      31894,
      31735,
      31576,
      31418,
      31261,
      31105,
      30949,
      30794,
      30640,
      30487,
      30335,
      30183,
      30032,
      29882,
      29733,
      29584,
      29436,
      29289,
      29142,
      28997,
      28852,
      28707,
      28564,
      28421,
      28279,
      28138,
      27997,
      27857,
      27718,
      27579,
      27441,
      27304,
      27167,
      27032,
      26896,
      26762,
      26628,
      26495,
      26362,
      26231,
      26100,
      25969,
      25839,
      25710,
      25581,
      25454,
      25326,
      25200,
      25074,
      24948,
      24823,
    ],
  },
  {
    totalYear: '21',
    supply: '0.52%',
    annualWeeklyBatch: '1,306,671',
    rewards: [
      28474,
      28331,
      28190,
      28049,
      27909,
      27769,
      27630,
      27492,
      27355,
      27218,
      27082,
      26946,
      26812,
      26678,
      26544,
      26411,
      26279,
      26148,
      26017,
      25887,
      25758,
      25629,
      25501,
      25373,
      25246,
      25120,
      24995,
      24870,
      24745,
      24622,
      24498,
      24376,
      24254,
      24133,
      24012,
      23892,
      23773,
      23654,
      23535,
      23418,
      23301,
      23184,
      23068,
      22953,
      22838,
      22724,
      22610,
      22497,
      22385,
      22273,
      22162,
      22051,
    ],
  },
  {
    totalYear: '22',
    supply: '0.46%',
    annualWeeklyBatch: '1,160,715',
    rewards: [
      25293,
      25167,
      25041,
      24916,
      24791,
      24667,
      24544,
      24421,
      24299,
      24178,
      24057,
      23936,
      23817,
      23698,
      23579,
      23461,
      23344,
      23227,
      23111,
      22996,
      22881,
      22766,
      22652,
      22539,
      22426,
      22314,
      22203,
      22092,
      21981,
      21871,
      21762,
      21653,
      21545,
      21437,
      21330,
      21223,
      21117,
      21012,
      20907,
      20802,
      20698,
      20595,
      20492,
      20389,
      20287,
      20186,
      20085,
      19984,
      19884,
      19785,
      19686,
      19588,
    ],
  },
  {
    totalYear: '23',
    supply: '0.41%',
    annualWeeklyBatch: '1,031,065',
    rewards: [
      22468,
      22356,
      22244,
      22133,
      22022,
      21912,
      21802,
      21693,
      21585,
      21477,
      21370,
      21263,
      21156,
      21051,
      20945,
      20841,
      20737,
      20633,
      20530,
      20427,
      20325,
      20223,
      20122,
      20022,
      19921,
      19822,
      19723,
      19624,
      19526,
      19428,
      19331,
      19235,
      19138,
      19043,
      18947,
      18853,
      18758,
      18665,
      18571,
      18478,
      18386,
      18294,
      18203,
      18112,
      18021,
      17931,
      17841,
      17752,
      17663,
      17575,
      17487,
      17400,
    ],
  },
  {
    totalYear: '24',
    supply: '0.37%',
    annualWeeklyBatch: '915,896',
    rewards: [
      19958,
      19859,
      19759,
      19661,
      19562,
      19464,
      19367,
      19270,
      19174,
      19078,
      18983,
      18888,
      18793,
      18699,
      18606,
      18513,
      18420,
      18328,
      18237,
      18145,
      18055,
      17964,
      17875,
      17785,
      17696,
      17608,
      17520,
      17432,
      17345,
      17258,
      17172,
      17086,
      17001,
      16916,
      16831,
      16747,
      16663,
      16580,
      16497,
      16414,
      16332,
      16251,
      16169,
      16089,
      16008,
      15928,
      15849,
      15769,
      15690,
      15612,
      15534,
      15456,
    ],
  },
  {
    totalYear: '25',
    supply: '0.33%',
    annualWeeklyBatch: '813,595',
    rewards: [
      17729,
      17640,
      17552,
      17464,
      17377,
      17290,
      17204,
      17118,
      17032,
      16947,
      16862,
      16778,
      16694,
      16611,
      16528,
      16445,
      16363,
      16281,
      16200,
      16119,
      16038,
      15958,
      15878,
      15799,
      15720,
      15641,
      15563,
      15485,
      15408,
      15330,
      15254,
      15178,
      15102,
      15026,
      14951,
      14876,
      14802,
      14728,
      14654,
      14581,
      14508,
      14436,
      14363,
      14292,
      14220,
      14149,
      14078,
      14008,
      13938,
      13868,
      13799,
      13730,
    ],
  },
  {
    totalYear: '26',
    supply: '0.29%',
    annualWeeklyBatch: '722,715',
    rewards: [
      15749,
      15670,
      15592,
      15514,
      15436,
      15359,
      15282,
      15206,
      15130,
      15054,
      14979,
      14904,
      14829,
      14755,
      14681,
      14608,
      14535,
      14462,
      14390,
      14318,
      14247,
      14175,
      14104,
      14034,
      13964,
      13894,
      13824,
      13755,
      13687,
      13618,
      13550,
      13482,
      13415,
      13348,
      13281,
      13215,
      13149,
      13083,
      13017,
      12952,
      12888,
      12823,
      12759,
      12695,
      12632,
      12569,
      12506,
      12443,
      12381,
      12319,
      12257,
      12196,
    ],
  },
  {
    totalYear: '27',
    supply: '0.26%',
    annualWeeklyBatch: '641,989',
    rewards: [
      13990,
      13920,
      13850,
      13781,
      13712,
      13643,
      13575,
      13507,
      13440,
      13373,
      13306,
      13239,
      13173,
      13107,
      13042,
      12976,
      12911,
      12847,
      12783,
      12719,
      12655,
      12592,
      12529,
      12466,
      12404,
      12342,
      12280,
      12219,
      12158,
      12097,
      12036,
      11976,
      11916,
      11857,
      11798,
      11739,
      11680,
      11621,
      11563,
      11506,
      11448,
      11391,
      11334,
      11277,
      11221,
      11165,
      11109,
      11053,
      10998,
      10943,
      10888,
      10834,
    ],
  },
  {
    totalYear: '28',
    supply: '0.23%',
    annualWeeklyBatch: '570,279',
    rewards: [
      12427,
      12365,
      12303,
      12242,
      12180,
      12119,
      12059,
      11999,
      11939,
      11879,
      11819,
      11760,
      11702,
      11643,
      11585,
      11527,
      11469,
      11412,
      11355,
      11298,
      11242,
      11185,
      11129,
      11074,
      11018,
      10963,
      10909,
      10854,
      10800,
      10746,
      10692,
      10639,
      10585,
      10532,
      10480,
      10427,
      10375,
      10323,
      10272,
      10220,
      10169,
      10118,
      10068,
      10018,
      9967,
      9918,
      9868,
      9819,
      9770,
      9721,
      9672,
      9624,
    ],
  },
  {
    totalYear: '29',
    supply: '0.20%',
    annualWeeklyBatch: '506,580',
    rewards: [
      11039,
      10984,
      10929,
      10874,
      10820,
      10766,
      10712,
      10658,
      10605,
      10552,
      10499,
      10447,
      10395,
      10343,
      10291,
      10239,
      10188,
      10137,
      10087,
      10036,
      9986,
      9936,
      9886,
      9837,
      9788,
      9739,
      9690,
      9642,
      9593,
      9545,
      9498,
      9450,
      9403,
      9356,
      9309,
      9263,
      9216,
      9170,
      9124,
      9079,
      9033,
      8988,
      8943,
      8899,
      8854,
      8810,
      8766,
      8722,
      8678,
      8635,
      8592,
      8549,
    ],
  },
  {
    totalYear: '30',
    supply: '0.18%',
    annualWeeklyBatch: '449,994',
    rewards: [
      9806,
      9757,
      9708,
      9660,
      9611,
      9563,
      9515,
      9468,
      9420,
      9373,
      9326,
      9280,
      9233,
      9187,
      9141,
      9096,
      9050,
      9005,
      8960,
      8915,
      8871,
      8826,
      8782,
      8738,
      8694,
      8651,
      8608,
      8565,
      8522,
      8479,
      8437,
      8395,
      8353,
      8311,
      8269,
      8228,
      8187,
      8146,
      8105,
      8065,
      8024,
      7984,
      7944,
      7905,
      7865,
      7826,
      7787,
      7748,
      7709,
      7670,
      7632,
      7594,
    ],
  },
  {
    totalYear: '31',
    supply: '0.16%',
    annualWeeklyBatch: '399,733',
    rewards: [
      8711,
      8667,
      8624,
      8581,
      8538,
      8495,
      8453,
      8410,
      8368,
      8326,
      8285,
      8243,
      8202,
      8161,
      8120,
      8080,
      8039,
      7999,
      7959,
      7919,
      7880,
      7840,
      7801,
      7762,
      7723,
      7685,
      7646,
      7608,
      7570,
      7532,
      7494,
      7457,
      7420,
      7383,
      7346,
      7309,
      7272,
      7236,
      7200,
      7164,
      7128,
      7092,
      7057,
      7022,
      6987,
      6952,
      6917,
      6882,
      6848,
      6814,
      6780,
      6746,
    ],
  },
  {
    totalYear: '32',
    supply: '0.14%',
    annualWeeklyBatch: '355,081',
    rewards: [
      7738,
      7699,
      7660,
      7622,
      7584,
      7546,
      7508,
      7471,
      7433,
      7396,
      7359,
      7323,
      7286,
      7249,
      7213,
      7177,
      7141,
      7106,
      7070,
      7035,
      7000,
      6965,
      6930,
      6895,
      6861,
      6826,
      6792,
      6758,
      6724,
      6691,
      6657,
      6624,
      6591,
      6558,
      6525,
      6493,
      6460,
      6428,
      6396,
      6364,
      6332,
      6300,
      6269,
      6237,
      6206,
      6175,
      6144,
      6114,
      6083,
      6053,
      6022,
      5992,
    ],
  },
  {
    totalYear: '33',
    supply: '0.13%',
    annualWeeklyBatch: '315,419',
    rewards: [
      6873,
      6839,
      6805,
      6771,
      6737,
      6703,
      6670,
      6636,
      6603,
      6570,
      6537,
      6505,
      6472,
      6440,
      6408,
      6375,
      6344,
      6312,
      6280,
      6249,
      6218,
      6187,
      6156,
      6125,
      6094,
      6064,
      6033,
      6003,
      5973,
      5943,
      5914,
      5884,
      5855,
      5825,
      5796,
      5767,
      5739,
      5710,
      5681,
      5653,
      5625,
      5596,
      5568,
      5541,
      5513,
      5485,
      5458,
      5431,
      5404,
      5376,
      5350,
      5323,
    ],
  },
  {
    totalYear: '34',
    supply: '0.11%',
    annualWeeklyBatch: '280,187',
    rewards: [
      6106,
      6075,
      6045,
      6014,
      5984,
      5954,
      5925,
      5895,
      5866,
      5836,
      5807,
      5778,
      5749,
      5720,
      5692,
      5663,
      5635,
      5607,
      5579,
      5551,
      5523,
      5496,
      5468,
      5441,
      5414,
      5386,
      5360,
      5333,
      5306,
      5280,
      5253,
      5227,
      5201,
      5175,
      5149,
      5123,
      5098,
      5072,
      5047,
      5021,
      4996,
      4971,
      4946,
      4922,
      4897,
      4873,
      4848,
      4824,
      4800,
      4776,
      4752,
      4728,
    ],
  },
  {
    totalYear: '35',
    supply: '0.10%',
    annualWeeklyBatch: '248,890',
    rewards: [
      5424,
      5396,
      5370,
      5343,
      5316,
      5289,
      5263,
      5237,
      5210,
      5184,
      5158,
      5133,
      5107,
      5081,
      5056,
      5031,
      5006,
      4981,
      4956,
      4931,
      4906,
      4882,
      4857,
      4833,
      4809,
      4785,
      4761,
      4737,
      4713,
      4690,
      4666,
      4643,
      4620,
      4597,
      4574,
      4551,
      4528,
      4505,
      4483,
      4461,
      4438,
      4416,
      4394,
      4372,
      4350,
      4328,
      4307,
      4285,
      4264,
      4242,
      4221,
      4200,
    ],
  },
  {
    totalYear: '36',
    supply: '0.09%',
    annualWeeklyBatch: '221,089',
    rewards: [
      4818,
      4794,
      4770,
      4746,
      4722,
      4699,
      4675,
      4652,
      4628,
      4605,
      4582,
      4559,
      4537,
      4514,
      4491,
      4469,
      4446,
      4424,
      4402,
      4380,
      4358,
      4336,
      4315,
      4293,
      4272,
      4250,
      4229,
      4208,
      4187,
      4166,
      4145,
      4124,
      4104,
      4083,
      4063,
      4043,
      4022,
      4002,
      3982,
      3962,
      3942,
      3923,
      3903,
      3884,
      3864,
      3845,
      3826,
      3807,
      3788,
      3769,
      3750,
      3731,
    ],
  },
  {
    totalYear: '37',
    supply: '0.08%',
    annualWeeklyBatch: '196,395',
    rewards: [
      4280,
      4258,
      4237,
      4216,
      4195,
      4174,
      4153,
      4132,
      4111,
      4091,
      4070,
      4050,
      4030,
      4010,
      3990,
      3970,
      3950,
      3930,
      3910,
      3891,
      3871,
      3852,
      3833,
      3814,
      3795,
      3776,
      3757,
      3738,
      3719,
      3701,
      3682,
      3664,
      3645,
      3627,
      3609,
      3591,
      3573,
      3555,
      3537,
      3520,
      3502,
      3485,
      3467,
      3450,
      3433,
      3415,
      3398,
      3381,
      3364,
      3348,
      3331,
      3314,
    ],
  },
  {
    totalYear: '38',
    supply: '0.07%',
    annualWeeklyBatch: '174,462',
    rewards: [
      3802,
      3783,
      3764,
      3745,
      3726,
      3708,
      3689,
      3671,
      3652,
      3634,
      3616,
      3598,
      3580,
      3562,
      3544,
      3526,
      3509,
      3491,
      3474,
      3456,
      3439,
      3422,
      3405,
      3388,
      3371,
      3354,
      3337,
      3320,
      3304,
      3287,
      3271,
      3254,
      3238,
      3222,
      3206,
      3190,
      3174,
      3158,
      3142,
      3127,
      3111,
      3095,
      3080,
      3065,
      3049,
      3034,
      3019,
      3004,
      2989,
      2974,
      2959,
      2944,
    ],
  },
  {
    totalYear: '39',
    supply: '0.06%',
    annualWeeklyBatch: '154,971',
    rewards: [
      3377,
      3360,
      3343,
      3327,
      3310,
      3293,
      3277,
      3261,
      3244,
      3228,
      3212,
      3196,
      3180,
      3164,
      3148,
      3132,
      3117,
      3101,
      3086,
      3070,
      3055,
      3040,
      3024,
      3009,
      2994,
      2979,
      2964,
      2950,
      2935,
      2920,
      2906,
      2891,
      2877,
      2862,
      2848,
      2834,
      2819,
      2805,
      2791,
      2777,
      2763,
      2750,
      2736,
      2722,
      2709,
      2695,
      2682,
      2668,
      2655,
      2642,
      2628,
      2615,
    ],
  },
  {
    totalYear: '40',
    supply: '0.06%',
    annualWeeklyBatch: '137,660',
    rewards: [
      3000,
      2985,
      2970,
      2955,
      2940,
      2926,
      2911,
      2896,
      2882,
      2867,
      2853,
      2839,
      2825,
      2811,
      2796,
      2782,
      2769,
      2755,
      2741,
      2727,
      2714,
      2700,
      2687,
      2673,
      2660,
      2646,
      2633,
      2620,
      2607,
      2594,
      2581,
      2568,
      2555,
      2542,
      2530,
      2517,
      2504,
      2492,
      2480,
      2467,
      2455,
      2443,
      2430,
      2418,
      2406,
      2394,
      2382,
      2370,
      2358,
      2346,
      2335,
      2323,
    ],
  },
  {
    totalYear: '41',
    supply: '0.05%',
    annualWeeklyBatch: '122,287',
    rewards: [
      2665,
      2651,
      2638,
      2625,
      2612,
      2599,
      2586,
      2573,
      2560,
      2547,
      2534,
      2522,
      2509,
      2497,
      2484,
      2472,
      2459,
      2447,
      2435,
      2423,
      2411,
      2398,
      2386,
      2375,
      2363,
      2351,
      2339,
      2327,
      2316,
      2304,
      2293,
      2281,
      2270,
      2258,
      2247,
      2236,
      2225,
      2214,
      2203,
      2192,
      2181,
      2170,
      2159,
      2148,
      2137,
      2127,
      2116,
      2105,
      2095,
      2084,
      2074,
      2064,
    ],
  },
  {
    totalYear: '42',
    supply: '0.04%',
    annualWeeklyBatch: '108,625',
    rewards: [
      2367,
      2355,
      2343,
      2332,
      2320,
      2308,
      2297,
      2285,
      2274,
      2263,
      2251,
      2240,
      2229,
      2218,
      2207,
      2196,
      2185,
      2174,
      2163,
      2152,
      2141,
      2131,
      2120,
      2109,
      2099,
      2088,
      2078,
      2067,
      2057,
      2047,
      2037,
      2026,
      2016,
      2006,
      1996,
      1986,
      1976,
      1966,
      1957,
      1947,
      1937,
      1927,
      1918,
      1908,
      1899,
      1889,
      1880,
      1870,
      1861,
      1852,
      1842,
      1833,
    ],
  },
  {
    totalYear: '43',
    supply: '0.04%',
    annualWeeklyBatch: '96,491',
    rewards: [
      2103,
      2092,
      2082,
      2071,
      2061,
      2051,
      2040,
      2030,
      2020,
      2010,
      2000,
      1990,
      1980,
      1970,
      1960,
      1950,
      1941,
      1931,
      1921,
      1912,
      1902,
      1893,
      1883,
      1874,
      1864,
      1855,
      1846,
      1837,
      1827,
      1818,
      1809,
      1800,
      1791,
      1782,
      1773,
      1764,
      1755,
      1747,
      1738,
      1729,
      1721,
      1712,
      1703,
      1695,
      1686,
      1678,
      1670,
      1661,
      1653,
      1645,
      1637,
      1628,
    ],
  },
  {
    totalYear: '44',
    supply: '0.03%',
    annualWeeklyBatch: '85,712',
    rewards: [
      1868,
      1858,
      1849,
      1840,
      1831,
      1822,
      1812,
      1803,
      1794,
      1785,
      1776,
      1768,
      1759,
      1750,
      1741,
      1733,
      1724,
      1715,
      1707,
      1698,
      1690,
      1681,
      1673,
      1664,
      1656,
      1648,
      1640,
      1631,
      1623,
      1615,
      1607,
      1599,
      1591,
      1583,
      1575,
      1567,
      1559,
      1552,
      1544,
      1536,
      1528,
      1521,
      1513,
      1506,
      1498,
      1491,
      1483,
      1476,
      1468,
      1461,
      1454,
      1446,
    ],
  },
  {
    totalYear: '45',
    supply: '0.03%',
    annualWeeklyBatch: '76,136',
    rewards: [
      1659,
      1651,
      1643,
      1634,
      1626,
      1618,
      1610,
      1602,
      1594,
      1586,
      1578,
      1570,
      1562,
      1554,
      1547,
      1539,
      1531,
      1524,
      1516,
      1508,
      1501,
      1493,
      1486,
      1478,
      1471,
      1464,
      1456,
      1449,
      1442,
      1435,
      1428,
      1420,
      1413,
      1406,
      1399,
      1392,
      1385,
      1378,
      1371,
      1365,
      1358,
      1351,
      1344,
      1337,
      1331,
      1324,
      1318,
      1311,
      1304,
      1298,
      1291,
      1285,
    ],
  },
  {
    totalYear: '46',
    supply: '0.03%',
    annualWeeklyBatch: '67,632',
    rewards: [
      1474,
      1466,
      1459,
      1452,
      1445,
      1437,
      1430,
      1423,
      1416,
      1409,
      1402,
      1395,
      1388,
      1381,
      1374,
      1367,
      1360,
      1353,
      1347,
      1340,
      1333,
      1327,
      1320,
      1313,
      1307,
      1300,
      1294,
      1287,
      1281,
      1274,
      1268,
      1262,
      1255,
      1249,
      1243,
      1237,
      1230,
      1224,
      1218,
      1212,
      1206,
      1200,
      1194,
      1188,
      1182,
      1176,
      1170,
      1164,
      1159,
      1153,
      1147,
      1141,
    ],
  },
  {
    totalYear: '47',
    supply: '0.02%',
    annualWeeklyBatch: '60,078',
    rewards: [
      1309,
      1303,
      1296,
      1290,
      1283,
      1277,
      1270,
      1264,
      1258,
      1251,
      1245,
      1239,
      1233,
      1227,
      1220,
      1214,
      1208,
      1202,
      1196,
      1190,
      1184,
      1178,
      1173,
      1167,
      1161,
      1155,
      1149,
      1143,
      1138,
      1132,
      1126,
      1121,
      1115,
      1110,
      1104,
      1099,
      1093,
      1088,
      1082,
      1077,
      1071,
      1066,
      1061,
      1055,
      1050,
      1045,
      1040,
      1034,
      1029,
      1024,
      1019,
      1014,
    ],
  },
  {
    totalYear: '48',
    supply: '0.02%',
    annualWeeklyBatch: '53,372',
    rewards: [
      1163,
      1157,
      1151,
      1146,
      1140,
      1134,
      1129,
      1123,
      1117,
      1112,
      1106,
      1101,
      1095,
      1090,
      1084,
      1079,
      1073,
      1068,
      1063,
      1057,
      1052,
      1047,
      1042,
      1036,
      1031,
      1026,
      1021,
      1016,
      1011,
      1006,
      1001,
      996,
      991,
      986,
      981,
      976,
      971,
      966,
      961,
      956,
      952,
      947,
      942,
      937,
      933,
      928,
      923,
      919,
      914,
      910,
      905,
      901,
    ],
  },
  {
    totalYear: '49',
    supply: '0.02%',
    annualWeeklyBatch: '47,409',
    rewards: [
      1033,
      1028,
      1023,
      1018,
      1013,
      1008,
      1002,
      997,
      992,
      988,
      983,
      978,
      973,
      968,
      963,
      958,
      953,
      949,
      944,
      939,
      935,
      930,
      925,
      921,
      916,
      911,
      907,
      902,
      898,
      893,
      889,
      884,
      880,
      876,
      871,
      867,
      863,
      858,
      854,
      850,
      845,
      841,
      837,
      833,
      829,
      824,
      820,
      816,
      812,
      808,
      804,
      800,
    ],
  },
  {
    totalYear: '50',
    supply: '0.02%',
    annualWeeklyBatch: '42,116',
    rewards: [
      918,
      913,
      909,
      904,
      899,
      895,
      890,
      886,
      882,
      877,
      873,
      868,
      864,
      860,
      855,
      851,
      847,
      843,
      839,
      834,
      830,
      826,
      822,
      818,
      814,
      810,
      806,
      802,
      798,
      794,
      790,
      786,
      782,
      778,
      774,
      770,
      766,
      762,
      759,
      755,
      751,
      747,
      743,
      740,
      736,
      732,
      729,
      725,
      721,
      718,
      714,
      711,
    ],
  },
  {
    totalYear: '51',
    supply: '0.01%',
    annualWeeklyBatch: '37,406',
    rewards: [
      815,
      811,
      807,
      803,
      799,
      795,
      791,
      787,
      783,
      779,
      775,
      771,
      768,
      764,
      760,
      756,
      752,
      749,
      745,
      741,
      737,
      734,
      730,
      726,
      723,
      719,
      716,
      712,
      708,
      705,
      701,
      698,
      694,
      691,
      687,
      684,
      681,
      677,
      674,
      670,
      667,
      664,
      660,
      657,
      654,
      651,
      647,
      644,
      641,
      638,
      634,
      631,
    ],
  },
  {
    totalYear: '52',
    supply: '0.01%',
    annualWeeklyBatch: '33,234',
    rewards: [
      724,
      720,
      717,
      713,
      710,
      706,
      703,
      699,
      696,
      692,
      689,
      685,
      682,
      678,
      675,
      672,
      668,
      665,
      662,
      658,
      655,
      652,
      649,
      645,
      642,
      639,
      636,
      632,
      629,
      626,
      623,
      620,
      617,
      614,
      611,
      608,
      605,
      602,
      599,
      596,
      593,
      590,
      587,
      584,
      581,
      578,
      575,
      572,
      569,
      566,
      564,
      561,
    ],
  },
  {
    totalYear: '53',
    supply: '0.01%',
    annualWeeklyBatch: '29,520',
    rewards: [
      643,
      640,
      637,
      634,
      630,
      627,
      624,
      621,
      618,
      615,
      612,
      609,
      606,
      603,
      600,
      597,
      594,
      591,
      588,
      585,
      582,
      579,
      576,
      573,
      570,
      567,
      565,
      562,
      559,
      556,
      553,
      551,
      548,
      545,
      542,
      540,
      537,
      534,
      532,
      529,
      526,
      524,
      521,
      519,
      516,
      513,
      511,
      508,
      506,
      503,
      501,
      498,
    ],
  },
  {
    totalYear: '54',
    supply: '0.01%',
    annualWeeklyBatch: '26,221',
    rewards: [
      571,
      569,
      566,
      563,
      560,
      557,
      554,
      552,
      549,
      546,
      543,
      541,
      538,
      535,
      533,
      530,
      527,
      525,
      522,
      519,
      517,
      514,
      512,
      509,
      507,
      504,
      502,
      499,
      497,
      494,
      492,
      489,
      487,
      484,
      482,
      479,
      477,
      475,
      472,
      470,
      468,
      465,
      463,
      461,
      458,
      456,
      454,
      451,
      449,
      447,
      445,
      442,
    ],
  },
  {
    totalYear: '55',
    supply: '0.01%',
    annualWeeklyBatch: '23,293',
    rewards: [
      508,
      505,
      503,
      500,
      497,
      495,
      493,
      490,
      488,
      485,
      483,
      480,
      478,
      476,
      473,
      471,
      468,
      466,
      464,
      461,
      459,
      457,
      455,
      452,
      450,
      448,
      446,
      443,
      441,
      439,
      437,
      435,
      432,
      430,
      428,
      426,
      424,
      422,
      420,
      417,
      415,
      413,
      411,
      409,
      407,
      405,
      403,
      401,
      399,
      397,
      395,
      393,
    ],
  },
  {
    totalYear: '56',
    supply: '0.01%',
    annualWeeklyBatch: '20,692',
    rewards: [
      451,
      449,
      446,
      444,
      442,
      440,
      438,
      435,
      433,
      431,
      429,
      427,
      425,
      422,
      420,
      418,
      416,
      414,
      412,
      410,
      408,
      406,
      404,
      402,
      400,
      398,
      396,
      394,
      392,
      390,
      388,
      386,
      384,
      382,
      380,
      378,
      376,
      375,
      373,
      371,
      369,
      367,
      365,
      363,
      362,
      360,
      358,
      356,
      354,
      353,
      351,
      349,
    ],
  },
  {
    totalYear: '57',
    supply: '0.01%',
    annualWeeklyBatch: '18,381',
    rewards: [
      401,
      399,
      397,
      395,
      393,
      391,
      389,
      387,
      385,
      383,
      381,
      379,
      377,
      375,
      373,
      371,
      370,
      368,
      366,
      364,
      362,
      360,
      359,
      357,
      355,
      353,
      352,
      350,
      348,
      346,
      345,
      343,
      341,
      339,
      338,
      336,
      334,
      333,
      331,
      329,
      328,
      326,
      324,
      323,
      321,
      320,
      318,
      316,
      315,
      313,
      312,
      310,
    ],
  },
  {
    totalYear: '58',
    supply: '0.01%',
    annualWeeklyBatch: '16,329',
    rewards: [
      356,
      354,
      352,
      350,
      349,
      347,
      345,
      344,
      342,
      340,
      338,
      337,
      335,
      333,
      332,
      330,
      328,
      327,
      325,
      323,
      322,
      320,
      319,
      317,
      315,
      314,
      312,
      311,
      309,
      308,
      306,
      305,
      303,
      302,
      300,
      299,
      297,
      296,
      294,
      293,
      291,
      290,
      288,
      287,
      285,
      284,
      283,
      281,
      280,
      278,
      277,
      276,
    ],
  },
  {
    totalYear: '59',
    supply: '0.01%',
    annualWeeklyBatch: '14,504',
    rewards: [
      316,
      314,
      313,
      311,
      310,
      308,
      307,
      305,
      304,
      302,
      301,
      299,
      298,
      296,
      295,
      293,
      292,
      290,
      289,
      287,
      286,
      284,
      283,
      282,
      280,
      279,
      277,
      276,
      275,
      273,
      272,
      271,
      269,
      268,
      267,
      265,
      264,
      263,
      261,
      260,
      259,
      257,
      256,
      255,
      253,
      252,
      251,
      250,
      248,
      247,
      246,
      245,
    ],
  },
  {
    totalYear: '60',
    supply: '0.01%',
    annualWeeklyBatch: '12,883',
    rewards: [
      281,
      279,
      278,
      277,
      275,
      274,
      272,
      271,
      270,
      268,
      267,
      266,
      264,
      263,
      262,
      260,
      259,
      258,
      257,
      255,
      254,
      253,
      251,
      250,
      249,
      248,
      246,
      245,
      244,
      243,
      242,
      240,
      239,
      238,
      237,
      236,
      234,
      233,
      232,
      231,
      230,
      229,
      227,
      226,
      225,
      224,
      223,
      222,
      221,
      220,
      218,
      217,
    ],
  },
  {
    totalYear: '61',
    supply: '0.00%',
    annualWeeklyBatch: '11,441',
    rewards: [
      249,
      248,
      247,
      246,
      244,
      243,
      242,
      241,
      240,
      238,
      237,
      236,
      235,
      234,
      232,
      231,
      230,
      229,
      228,
      227,
      226,
      224,
      223,
      222,
      221,
      220,
      219,
      218,
      217,
      216,
      215,
      213,
      212,
      211,
      210,
      209,
      208,
      207,
      206,
      205,
      204,
      203,
      202,
      201,
      200,
      199,
      198,
      197,
      196,
      195,
      194,
      193,
    ],
  },
  {
    totalYear: '62',
    supply: '0.00%',
    annualWeeklyBatch: '10,164',
    rewards: [
      222,
      220,
      219,
      218,
      217,
      216,
      215,
      214,
      213,
      212,
      211,
      210,
      209,
      208,
      207,
      205,
      204,
      203,
      202,
      201,
      200,
      199,
      198,
      197,
      196,
      195,
      194,
      193,
      193,
      192,
      191,
      190,
      189,
      188,
      187,
      186,
      185,
      184,
      183,
      182,
      181,
      180,
      179,
      179,
      178,
      177,
      176,
      175,
      174,
      173,
      172,
      172,
    ],
  },
  {
    totalYear: '63',
    supply: '0.00%',
    annualWeeklyBatch: '9,029',
    rewards: [
      197,
      196,
      195,
      194,
      193,
      192,
      191,
      190,
      189,
      188,
      187,
      186,
      185,
      184,
      183,
      183,
      182,
      181,
      180,
      179,
      178,
      177,
      176,
      175,
      174,
      174,
      173,
      172,
      171,
      170,
      169,
      168,
      168,
      167,
      166,
      165,
      164,
      163,
      163,
      162,
      161,
      160,
      159,
      159,
      158,
      157,
      156,
      155,
      155,
      154,
      153,
      152,
    ],
  },
  {
    totalYear: '64',
    supply: '0.00%',
    annualWeeklyBatch: '8,020',
    rewards: [
      175,
      174,
      173,
      172,
      171,
      170,
      170,
      169,
      168,
      167,
      166,
      165,
      165,
      164,
      163,
      162,
      161,
      161,
      160,
      159,
      158,
      157,
      157,
      156,
      155,
      154,
      153,
      153,
      152,
      151,
      150,
      150,
      149,
      148,
      147,
      147,
      146,
      145,
      144,
      144,
      143,
      142,
      142,
      141,
      140,
      139,
      139,
      138,
      137,
      137,
      136,
      135,
    ],
  },
  {
    totalYear: '65',
    supply: '0.00%',
    annualWeeklyBatch: '7,124',
    rewards: [
      155,
      154,
      154,
      153,
      152,
      151,
      151,
      150,
      149,
      148,
      148,
      147,
      146,
      145,
      145,
      144,
      143,
      143,
      142,
      141,
      140,
      140,
      139,
      138,
      138,
      137,
      136,
      136,
      135,
      134,
      134,
      133,
      132,
      132,
      131,
      130,
      130,
      129,
      128,
      128,
      127,
      126,
      126,
      125,
      125,
      124,
      123,
      123,
      122,
      121,
      121,
      120,
    ],
  },
  {
    totalYear: '66',
    supply: '0.00%',
    annualWeeklyBatch: '6,331',
    rewards: [
      138,
      137,
      137,
      136,
      135,
      135,
      134,
      133,
      133,
      132,
      131,
      131,
      130,
      129,
      129,
      128,
      127,
      127,
      126,
      125,
      125,
      124,
      124,
      123,
      122,
      122,
      121,
      120,
      120,
      119,
      119,
      118,
      117,
      117,
      116,
      116,
      115,
      115,
      114,
      113,
      113,
      112,
      112,
      111,
      111,
      110,
      110,
      109,
      108,
      108,
      107,
      107,
    ],
  },
  {
    totalYear: '67',
    supply: '0.00%',
    annualWeeklyBatch: '5,622',
    rewards: [
      123,
      122,
      121,
      121,
      120,
      119,
      119,
      118,
      118,
      117,
      117,
      116,
      115,
      115,
      114,
      114,
      113,
      113,
      112,
      111,
      111,
      110,
      110,
      109,
      109,
      108,
      108,
      107,
      106,
      106,
      105,
      105,
      104,
      104,
      103,
      103,
      102,
      102,
      101,
      101,
      100,
      100,
      99,
      99,
      98,
      98,
      97,
      97,
      96,
      96,
      95,
      95,
    ],
  },
  {
    totalYear: '68',
    supply: '0.00%',
    annualWeeklyBatch: '4,994',
    rewards: [
      109,
      108,
      108,
      107,
      107,
      106,
      106,
      105,
      105,
      104,
      104,
      103,
      102,
      102,
      101,
      101,
      100,
      100,
      99,
      99,
      98,
      98,
      97,
      97,
      96,
      96,
      96,
      95,
      95,
      94,
      94,
      93,
      93,
      92,
      92,
      91,
      91,
      90,
      90,
      90,
      89,
      89,
      88,
      88,
      87,
      87,
      86,
      86,
      86,
      85,
      85,
      84,
    ],
  },
  {
    totalYear: '69',
    supply: '0.00%',
    annualWeeklyBatch: '4,437',
    rewards: [
      97,
      96,
      96,
      95,
      95,
      94,
      94,
      93,
      93,
      92,
      92,
      91,
      91,
      91,
      90,
      90,
      89,
      89,
      88,
      88,
      87,
      87,
      87,
      86,
      86,
      85,
      85,
      84,
      84,
      84,
      83,
      83,
      82,
      82,
      82,
      81,
      81,
      80,
      80,
      80,
      79,
      79,
      78,
      78,
      78,
      77,
      77,
      76,
      76,
      76,
      75,
      75,
    ],
  },
  {
    totalYear: '70',
    supply: '0.00%',
    annualWeeklyBatch: '3,943',
    rewards: [
      86,
      85,
      85,
      85,
      84,
      84,
      83,
      83,
      83,
      82,
      82,
      81,
      81,
      80,
      80,
      80,
      79,
      79,
      78,
      78,
      78,
      77,
      77,
      77,
      76,
      76,
      75,
      75,
      75,
      74,
      74,
      74,
      73,
      73,
      72,
      72,
      72,
      71,
      71,
      71,
      70,
      70,
      70,
      69,
      69,
      69,
      68,
      68,
      68,
      67,
      67,
      67,
    ],
  },
  {
    totalYear: '71',
    supply: '0.00%',
    annualWeeklyBatch: '3,500',
    rewards: [
      76,
      76,
      76,
      75,
      75,
      74,
      74,
      74,
      73,
      73,
      73,
      72,
      72,
      71,
      71,
      71,
      70,
      70,
      70,
      69,
      69,
      69,
      68,
      68,
      68,
      67,
      67,
      67,
      66,
      66,
      66,
      65,
      65,
      65,
      64,
      64,
      64,
      63,
      63,
      63,
      62,
      62,
      62,
      61,
      61,
      61,
      61,
      60,
      60,
      60,
      59,
      59,
    ],
  },
  {
    totalYear: '72',
    supply: '0.00%',
    annualWeeklyBatch: '3,108',
    rewards: [
      68,
      67,
      67,
      67,
      66,
      66,
      66,
      65,
      65,
      65,
      64,
      64,
      64,
      63,
      63,
      63,
      63,
      62,
      62,
      62,
      61,
      61,
      61,
      60,
      60,
      60,
      59,
      59,
      59,
      59,
      58,
      58,
      58,
      57,
      57,
      57,
      57,
      56,
      56,
      56,
      55,
      55,
      55,
      55,
      54,
      54,
      54,
      54,
      53,
      53,
      53,
      52,
    ],
  },
  {
    totalYear: '73',
    supply: '0.00%',
    annualWeeklyBatch: '2,765',
    rewards: [
      60,
      60,
      60,
      59,
      59,
      59,
      58,
      58,
      58,
      58,
      57,
      57,
      57,
      56,
      56,
      56,
      56,
      55,
      55,
      55,
      54,
      54,
      54,
      54,
      53,
      53,
      53,
      53,
      52,
      52,
      52,
      52,
      51,
      51,
      51,
      51,
      50,
      50,
      50,
      50,
      49,
      49,
      49,
      49,
      48,
      48,
      48,
      48,
      47,
      47,
      47,
      47,
    ],
  },
  {
    totalYear: '74',
    supply: '0.00%',
    annualWeeklyBatch: '2,453',
    rewards: [
      53,
      53,
      53,
      53,
      52,
      52,
      52,
      52,
      51,
      51,
      51,
      51,
      50,
      50,
      50,
      50,
      49,
      49,
      49,
      49,
      48,
      48,
      48,
      48,
      47,
      47,
      47,
      47,
      46,
      46,
      46,
      46,
      46,
      45,
      45,
      45,
      45,
      44,
      44,
      44,
      44,
      44,
      43,
      43,
      43,
      43,
      42,
      42,
      42,
      42,
      42,
      41,
    ],
  },
  {
    totalYear: '75',
    supply: '0.00%',
    annualWeeklyBatch: '2,180',
    rewards: [
      48,
      47,
      47,
      47,
      47,
      46,
      46,
      46,
      46,
      45,
      45,
      45,
      45,
      45,
      44,
      44,
      44,
      44,
      43,
      43,
      43,
      43,
      43,
      42,
      42,
      42,
      42,
      41,
      41,
      41,
      41,
      41,
      40,
      40,
      40,
      40,
      40,
      39,
      39,
      39,
      39,
      39,
      38,
      38,
      38,
      38,
      38,
      38,
      37,
      37,
      37,
      37,
    ],
  },
  {
    totalYear: '76',
    supply: '0.00%',
    annualWeeklyBatch: '1,938',
    rewards: [
      42,
      42,
      42,
      42,
      41,
      41,
      41,
      41,
      41,
      40,
      40,
      40,
      40,
      40,
      39,
      39,
      39,
      39,
      39,
      38,
      38,
      38,
      38,
      38,
      37,
      37,
      37,
      37,
      37,
      36,
      36,
      36,
      36,
      36,
      36,
      35,
      35,
      35,
      35,
      35,
      35,
      34,
      34,
      34,
      34,
      34,
      34,
      33,
      33,
      33,
      33,
      33,
    ],
  },
  {
    totalYear: '77',
    supply: '0.00%',
    annualWeeklyBatch: '1,719',
    rewards: [
      37,
      37,
      37,
      37,
      37,
      37,
      36,
      36,
      36,
      36,
      36,
      35,
      35,
      35,
      35,
      35,
      35,
      34,
      34,
      34,
      34,
      34,
      34,
      33,
      33,
      33,
      33,
      33,
      33,
      32,
      32,
      32,
      32,
      32,
      32,
      31,
      31,
      31,
      31,
      31,
      31,
      31,
      30,
      30,
      30,
      30,
      30,
      30,
      29,
      29,
      29,
      29,
    ],
  },
  {
    totalYear: '78',
    supply: '0.00%',
    annualWeeklyBatch: '1,529',
    rewards: [
      33,
      33,
      33,
      33,
      33,
      32,
      32,
      32,
      32,
      32,
      32,
      32,
      31,
      31,
      31,
      31,
      31,
      31,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      28,
      28,
      28,
      28,
      28,
      28,
      28,
      27,
      27,
      27,
      27,
      27,
      27,
      27,
      26,
      26,
      26,
      26,
      26,
      26,
    ],
  },
  {
    totalYear: '79',
    supply: '0.00%',
    annualWeeklyBatch: '1,356',
    rewards: [
      30,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      28,
      28,
      28,
      28,
      28,
      28,
      28,
      27,
      27,
      27,
      27,
      27,
      27,
      27,
      26,
      26,
      26,
      26,
      26,
      26,
      26,
      26,
      25,
      25,
      25,
      25,
      25,
      25,
      25,
      25,
      24,
      24,
      24,
      24,
      24,
      24,
      24,
      24,
      23,
      23,
      23,
      23,
      23,
      23,
    ],
  },
  {
    totalYear: '80',
    supply: '0.00%',
    annualWeeklyBatch: '1,204',
    rewards: [
      26,
      26,
      26,
      26,
      26,
      26,
      25,
      25,
      25,
      25,
      25,
      25,
      25,
      25,
      24,
      24,
      24,
      24,
      24,
      24,
      24,
      24,
      24,
      23,
      23,
      23,
      23,
      23,
      23,
      23,
      23,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      20,
      20,
    ],
  },
  {
    totalYear: '81',
    supply: '0.00%',
    annualWeeklyBatch: '1,070',
    rewards: [
      23,
      23,
      23,
      23,
      23,
      23,
      23,
      23,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      22,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      21,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      18,
      18,
      18,
      18,
      18,
    ],
  },
  {
    totalYear: '82',
    supply: '0.00%',
    annualWeeklyBatch: '951',
    rewards: [
      21,
      21,
      21,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      19,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      16,
      16,
      16,
      16,
      16,
      16,
    ],
  },
  {
    totalYear: '83',
    supply: '0.00%',
    annualWeeklyBatch: '844',
    rewards: [
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      18,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      17,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      14,
      14,
      14,
      14,
    ],
  },
  {
    totalYear: '84',
    supply: '0.00%',
    annualWeeklyBatch: '751',
    rewards: [
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
    ],
  },
  {
    totalYear: '85',
    supply: '0.00%',
    annualWeeklyBatch: '666',
    rewards: [
      15,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      11,
      11,
      11,
      11,
      11,
    ],
  },
  {
    totalYear: '86',
    supply: '0.00%',
    annualWeeklyBatch: '593',
    rewards: [
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      12,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
    ],
  },
  {
    totalYear: '87',
    supply: '0.00%',
    annualWeeklyBatch: '524',
    rewards: [
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
    ],
  },
  {
    totalYear: '88',
    supply: '0.00%',
    annualWeeklyBatch: '467',
    rewards: [
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      10,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
    ],
  },
  {
    totalYear: '89',
    supply: '0.00%',
    annualWeeklyBatch: '415',
    rewards: [
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      9,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
    ],
  },
  {
    totalYear: '90',
    supply: '0.00%',
    annualWeeklyBatch: '369',
    rewards: [
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      8,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
    ],
  },
  {
    totalYear: '91',
    supply: '0.00%',
    annualWeeklyBatch: '331',
    rewards: [
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
    ],
  },
  {
    totalYear: '92',
    supply: '0.00%',
    annualWeeklyBatch: '289',
    rewards: [
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
    ],
  },
  {
    totalYear: '93',
    supply: '0.00%',
    annualWeeklyBatch: '258',
    rewards: [
      6,
      6,
      6,
      6,
      6,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
    ],
  },
  {
    totalYear: '94',
    supply: '0.00%',
    annualWeeklyBatch: '230',
    rewards: [
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
    ],
  },
  {
    totalYear: '95',
    supply: '0.00%',
    annualWeeklyBatch: '204',
    rewards: [
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      3,
      3,
      3,
      3,
    ],
  },
  {
    totalYear: '96',
    supply: '0.00%',
    annualWeeklyBatch: '181',
    rewards: [
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
    ],
  },
  {
    totalYear: '97',
    supply: '0.00%',
    annualWeeklyBatch: '157',
    rewards: [
      4,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
    ],
  },
  {
    totalYear: '98',
    supply: '0.00%',
    annualWeeklyBatch: '148',
    rewards: [
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
    ],
  },
  {
    totalYear: '99',
    supply: '0.00%',
    annualWeeklyBatch: '125',
    rewards: [
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
    ],
  },
  {
    totalYear: '100',
    supply: '0.00%',
    annualWeeklyBatch: '104',
    rewards: [
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
    ],
  },
]

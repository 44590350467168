export enum BrandRoutes {
  swarm = '/',
  mattereum = '/matr',
  fintiv = '/fintiv',
}

export enum BrandNames {
  swarm = 'Swarm',
  mattereum = 'Mattereum',
  fintiv = 'Fintiv',
}
